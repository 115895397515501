import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit {

  constructor(
    private pageTitle: PageTitleService
  ) {
    this.pageTitle.setTitle('Demo')
   }

  ngOnInit(): void {
  }

  onClick(){
    let x = document.querySelector("#offers");
    if (x){
        x.scrollIntoView();
      }
  }

}
