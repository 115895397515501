<section id="services" class="services section-bg">
    <div class="container">
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left;">Shopify</h2>
        </div>
    </div>
    <div class="container">
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Integration</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                Shopify is a fully hosted shopping cart, which can link to external payment gateways in order to
                complete transactions.

                Only a few simple steps are required in order to complete this process.

                You will need your WaafiPay Store ID and Authentication Key. These can be located in the configuration
                screen for the V2 hosted payment pages in the WaafiPay admin system.
            </p>
            <br>
            <p style="margin: 3 0px;" style="text-align: left;">
                When the customer reached the payment stage of the WooCommerce checkout, they will be presented with the
                option to pay using credit or debit card via WaafiPay. This will take them to the WaafiPay secure server
                to
                enter their card details. The result of the purchase attempt (authorised or otherwise) is sent back to
                the WooCommerce system to update the order details. The customer is also returned to the store.
            </p>
        </div>
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Payment page customisation</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                The payment page can be customised through the use of a CSS file. The core page display is based on the
                BootStrap 3 responsive layout. You should ensure that you are familiar with the underlying styles used
                within Bootstrap before making any CSS changes. For more details please check the Hosted Payment Page
                integration guide.
            </p>
            <br>
        </div>
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Verified by Visa and MasterCard SecureCode</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                After the consumer enters their card details, the payment gateway will check to see if that card is
                enrolled as part of the Verified by Visa or MasterCard SecureCode authentication systems (known as
                3D-Secure).
            </p>
            <br>
            <p style="margin: 3 0px;" style="text-align: left;">
                If the card is part of one of these authentication systems, then an additional page is displayed which
                requires the relevant authentication details to be entered. This is usually in the form of a password
                that has been assigned by the consumer or via a one-time code sent to the customers mobile.
            </p>
        </div>
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Live Transactions</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                When you are ready to start processing live transactions, you must ensure that the IP details of your
                Magento server have been entered into the Payment Page V2 configuration with the WaafiPay Merchant
                Administration System.
            </p>
            <br>
            <!-- <p style="margin: 3 0px;" style="text-align: left;">
                If the card is part of one of these authentication systems, then an additional page is displayed which requires the relevant authentication details to be entered. This is usually in the form of a password that has been assigned by the consumer or via a one-time code sent to the customers mobile.
            </p> -->
        </div>
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Mobile Wallets</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                These card numbers can be used when testing your integration to the payment gateway. These cards will
                not work for live transactions.
            </p>
            <br>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" style="text-align: left;">Wallet</th>
                        <th scope="col" style="text-align: left;">Mobile Number</th>
                        <th scope="col" style="text-align: left;">Pin</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align: left;">EVC Plus</td>
                        <td scope="row" style="text-align: left;">252615414470</td>
                        <td style="text-align: left;">1212</td>
                    </tr>
                    <tr>
                        <td style="text-align: left;">ZAAD Service</td>
                        <td scope="row" style="text-align: left;">5555 5555 5555 4444</td>
                        <td style="text-align: left;">000</td>
                    </tr>
                    <tr>
                        <td style="text-align: left;">SAHAL Service</td>
                        <td scope="row" style="text-align: left;">3411 1111 1111 1111</td>
                        <td style="text-align: left;">000</td>
                    </tr>
                    <tr>
                        <td style="text-align: left;">WAAFI SAB</td>
                        <td scope="row" style="text-align: left;">3411 1111 1111 1111</td>
                        <td style="text-align: left;">000</td>
                    </tr>
                    <tr>
                        <td style="text-align: left;">WAAFI International</td>
                        <td scope="row" style="text-align: left;">3411 1111 1111 1111</td>
                        <td style="text-align: left;">000</td>
                    </tr>
                    <tr>
                        <td style="text-align: left;">eBirr Service</td>
                        <td scope="row" style="text-align: left;">3411 1111 1111 1111</td>
                        <td style="text-align: left;">000</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Test Cards</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                These card numbers can be used when testing your integration to the payment gateway. These cards will
                not work for live transactions.
            </p>
            <br>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" style="text-align: left;">Network</th>
                        <th scope="col" style="text-align: left;">Card number</th>
                        <th scope="col" style="text-align: left;">Expire Date</th>
                        <th scope="col" style="text-align: left;">CVV</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align: left;">Visa Card</td>
                        <td scope="row" style="text-align: left;">4111 1111 1111 1111</td>
                        <td scope="row" style="text-align: left;">12/28</td>
                        <td style="text-align: left;">000</td>
                    </tr>
                    <tr>
                        <td style="text-align: left;">Master Card</td>
                        <td scope="row" style="text-align: left;">5555 5555 5555 4444</td>
                        <td scope="row" style="text-align: left;">12/28</td>
                        <td style="text-align: left;">000</td>
                    </tr>
                    <tr>
                        <td style="text-align: left;">American Express</td>
                        <td scope="row" style="text-align: left;">3411 1111 1111 1111</td>
                        <td scope="row" style="text-align: left;">12/28</td>
                        <td style="text-align: left;">000</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</section>