import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { MainComponent } from '../components/main/main.component';
import { HomeComponent } from '../components/contents/home/home.component';
import { MerchantServicesComponent } from '../components/contents/merchant-services/merchant-services.component'
import { TapAndPayComponent } from '../components/contents/tap-and-pay/tap-and-pay.component'
import { CreditCarsComponent } from '../components/contents/credit-cars/credit-cars.component'
import { WagesProtectionComponent } from '../components/contents/wages-protection/wages-protection.component'
import { AboutUsComponent } from '../components/contents/about-us/about-us.component'
import { WebInegrationComponent } from '../components/contents/web-inegration/web-inegration.component'
import { DemoComponent } from '../components/contents/demo/demo.component';
import { WooCommerceComponent } from '../components/contents/web-inegration/woo-commerce/woo-commerce.component';
import { ShopifyComponent } from '../components/contents/web-inegration/shopify/shopify.component';


const routes: Routes = [
  {
    path: "", component: MainComponent,
    children: [
      { path: "", component: HomeComponent },
      { path: "merchant-services", component: MerchantServicesComponent },
      { path: "tap-and-pay", component: TapAndPayComponent },
      { path: "credit-cars", component: CreditCarsComponent },
      { path: "wages-protection", component: WagesProtectionComponent },
      { path: "easy-integration", component: WebInegrationComponent },
      { path: "knowledge-base/woocommerce", component: WooCommerceComponent },
      { path: "knowledge-base/shopify", component: ShopifyComponent },
      { path: "about-us", component: AboutUsComponent },
      { path: "demo", component: DemoComponent },
    ]
  },

];
const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
