
<!-- ======= Cars Section ======= -->
<section id="hero" class="d-flex flex-column justify-content-center align-items-center" style="margin-bottom: 0px !important;">
    <div class="container" data-aos="fade-in">
      <div class="row">
        <div class="col-6">
          <h1>Credit Card Supplier Payments in our payment platform.</h1>
          <p>Our Credit card Supplier payment remains a challenge for businesses, with slow internal processes and insufficient automation. </p>
          <div class="d-flex align-items-center">
            <i class="bx bx-down-arrow-alt get-started-icon"></i>
            <a (click)="onClick()" style="cursor: pointer;" class="btn-get-started scrollto">Learn Our Credit Cards</a>
          </div>
        </div>
        <div class="col-6">
          <img src="../assets/img/tapsii/mobile-pay-6.png" style="margin-left: 35px;" width="110%" height="100%" alt="" srcset="">
        </div>
      </div>
     
    </div>
  </section>
   <!-- ======= offers Section ======= -->
   <section id="offers" class="offers section-bg">
    <div class="container">
      <div class="row">
        <div class="col-5  justify-content-center align-items-stretch" data-aos="fade-right">
            <img src="../assets/img/tapsii/mobile-pay-8.png" width="100%" height="100%" alt="" srcset="">
        </div>

        <div class="col-7  align-items-stretch justify-content-center">
          <h3 data-aos="fade-up">WaafiPay Credit Card payment Solutions</h3>
    

          <div class="icon-box" data-aos="fade-up">
            <div class="icon"><i class="icofont-mastercard"></i></div>
            <h4 class="title"><a href="">Master Card Integration - <span style="font-weight: 100;">We Integrating a Master Credit Card Payment Processing solution offers Our applications, users the ability to pay for goods or services via debit and credit cards.</span> </a></h4>
            
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-visa-alt"></i></div>
            <h4 class="title"><a href="">Visa Card Integration - <span style="font-weight: 100;">We Integrating a visa Credit Card Payment Processing solution offers Our applications, users the ability to pay for goods or services via debit and credit cards.</span></a></h4>
    
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-bank-transfer-alt"></i></div>
            <h4 class="title"><a href="">Bank Payments Integration - <span style="font-weight: 100;">You want your website to accept online payments it is necessary. Payment gateways connect the merchant's website and their payment processing bank, allowing for the secure transference of financial details.</span></a></h4>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!-- End offers Section -->
