import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private toastrService: ToastrService) { }

  showSuccessMessage(message: string) {
       this.toastrService.success(message, undefined, {
            positionClass: 'toast-top-center'
       });
  }
  showErrorMessage(message: string) {
       this.toastrService.error(message, undefined, {
            positionClass: 'toast-top-center'
       });
  }
}
