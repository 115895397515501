import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-tap-and-pay',
  templateUrl: './tap-and-pay.component.html',
  styleUrls: ['./tap-and-pay.component.css']
})
export class TapAndPayComponent implements OnInit {

  constructor(
    private pageTitle: PageTitleService
  ) {
    this.pageTitle.setTitle('Tap & Pay')
   }

  ngOnInit(): void {
  }

  onClick(){
    let x = document.querySelector("#nfc");
    if (x){
        x.scrollIntoView();
  }

}

}
