<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex flex-column justify-content-center align-items-center">
  <div class="container" data-aos="fade-in">
    <div class="row">
      <div class="col-6">
        <h1>Welcome to WaafiPay!</h1>
        <p>We've simplified the payment process To Grow your business by expanding into new markets.</p>
        <div class="d-flex align-items-center">
          <i class="bx bxs-right-arrow-alt get-started-icon"></i>
          <a href="/merchant-services" class="btn-get-started scrollto">Learn About Our Services</a>
        </div>
      </div>
      <div class="col-6">
        <img src="../assets/img/payment-1.png" style="margin-left: 35px;" width="110%" height="100%" alt="" srcset="">
      </div>
    </div>

  </div>
</section>


<!-- ======= Why Us Section ======= -->
<section id="why-us" class="why-us">
  <div class="container">

    <div class="row">
      <div class="col-xl-4 col-lg-5" data-aos="fade-up">
        <div class="content">
          <img src="../assets/img/secure.png" width="80px" height="80px" style="display: block; margin: 0 auto;" alt=""
            srcset="">
          <h3 style="margin-top: 20px;">Secure Payment, PCI DSS Certified</h3>
          <p>
            In an evolving world, every business needs a way to accept online payments securely and conveniently. All
            services needed to support your business is available with us from capturing customer’s payment account to
            online settlement with merchant accounts.
          </p>
          <div class="text-center">
            <a href="#" class="more-btn">Learn More <i class="bx bx-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 d-flex">
        <div class="icon-boxes d-flex flex-column justify-content-center">
          <div class="row">
            <div class="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div class="icon-box mt-4 mt-xl-0">
                <!-- <i class="bx bx-plug"></i> -->
                <img src="../assets/img/integration.png" width="60px" height="60px" style="margin-bottom: 20px" alt=""
                  srcset="">
                <h4>Ease Integration</h4>
                <p class="short">
                  Integrate with a variety of methods to create a customized solution that fits your needs. Collectively
                  our team represents the best to help you integrate with us, share SDKs and sample codes in majority of
                  programming languages. In addition to hosted pages which increase your productivity and security.
                </p>
              </div>
            </div>
            <div class="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <div class="icon-box mt-4 mt-xl-0">
                <!-- <i class="bx bx-cube-alt"></i> -->
                <img src="../assets/img/accept-payment.png" width="60px" height="60px" style="margin-bottom: 20px"
                  alt="" srcset="">
                <h4>Accept Payment Online With Ease</h4>
                <p class="short">Ready to accept payments online? We are the hub for you! We offer the best online
                  payment options for businesses, large and small. We make your business accept payments from variety of
                  Mobile Money Providers, Credit Cards and Banks.</p>
              </div>
            </div>
            <div class="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
              <div class="icon-box mt-4 mt-xl-0">
                <!-- <i class="bx bx-images"></i> -->
                <img src="../assets/img/scaleup.png" width="60px" height="60px" style="margin-bottom: 20px" alt=""
                  srcset="">
                <h4>Scale Up Your Online Business</h4>
                <p class="short">What we do helps reduce fraud, increase good transactions, and make the payments
                  ecosystem better for all. We're a regional leader and we work in a way that best suits your
                  lifestyles, work styles, and individuality.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End Why Us Section -->

<!-- ======= About Section ======= -->
<section id="about" class="about section-bg">
  <div class="container">

    <div class="row">
      <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
        <a href="https://www.youtube.com/watch?v=3U5dlHGHuUQ" class="venobox play-btn mb-4" data-vbtype="video"
          data-autoplay="true"></a>
      </div>

      <div
        class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
        <h4 data-aos="fade-up">About WaafiPay</h4>
        <h3 data-aos="fade-up">This is how we do it</h3>
        <p data-aos="fade-up">We take the risk out of doing crossborder payments in Middle East & Africa.</p>

        <div class="icon-box" data-aos="fade-up">
          <div class="icon"><i class="icofont-dashboard-web"></i></div>
          <h4 class="title"><a href="">Merchant & Cashier Providers.</a></h4>
          <p class="description">Merchant Account Providers give businesses the ability to accept debit and credit cards
            in payment for goods and services and The cashier is a sort of laying or buffering between the merchant and
            the payment processor</p>
        </div>

        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i class="icofont-handshake-deal"></i></div>
          <h4 class="title"><a href="">Purchasing Mobile wallet & Credit Cars Integration</a></h4>
          <p class="description">Mobile payment generally refer to payment services operated under financial regulation
            and performed from or via a mobile device.</p>
        </div>

        <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
          <div class="icon"><i class="icofont-stock-mobile"></i></div>
          <h4 class="title"><a href="">Tap & Pay TapSii NFC Integration</a></h4>
          <p class="description">Tap and pay is a form of payment that allows a customer to tap a card or device against
            your card reader to pay instead of inserting a card. When customers want to pay they simply wave or tap
            their card over the contactless terminal</p>
        </div>
        <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
          <div class="icon"><i class="icofont-verification-check"></i></div>
          <h4 class="title"><a href="">Guaranteed payments.</a></h4>
          <p class="description">A guaranteed payment is a specific term in the Internal Revenue Code, which is defined
            as payments to a partner (in a partnership) or a member (in a limited liability company) in his or her
            partner or member capacity for services rendered to the partnership or limited liability without regard to
            the income of the entity</p>
        </div>

      </div>
    </div>

  </div>
</section>
<!-- End About Section -->


<!-- ======= Services Section ======= -->
<section id="services" class="services section-bg">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Services</h2>
      <p>Our payment service enabling cash deposits and withdrawals, execution of payment transactions, issuing or
        acquisition of payment instruments, the provision of money transfer services or any other service functional to
        the transfer of money and includes the issuance of electronic money and electronic funds transfers but does not
        include the provision of solely online or telecommunication services or network access</p>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6" data-aos="fade-up">
        <div class="icon-box">
          <div class="icon"><i class="icofont-computer"></i></div>
          <h4 class="title"><a href="">Merchant Services</a></h4>
          <p class="description">An Ideal Payment Platform for Business & Personal at Anytime, at Anywhere that<br>
            enables merchants to process payments.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
        <div class="icon-box">
          <div class="icon"><i class="icofont-gears"></i></div>
          <h4 class="title"><a href="">Easy Integration</a></h4>
          <p class="description">With Easiest Integration, Give Your Customer A Seamless Checkout Experience. Affordable
            & Fast Payouts.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
        <div class="icon-box">
          <div class="icon"><i class="icofont-stock-mobile"></i></div>
          <h4 class="title"><a href="">Point of Sale</a></h4>
          <p class="description">POS aims to address accepting digital payments by small business owners and democratise
            the entire process for these businesses by an individual merchant</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
        <div class="icon-box">
          <div class="icon"><i class="icofont-wallet"></i></div>
          <h4 class="title"><a href="">Mobile Money</a></h4>
          <p class="description">Now easily transfer money to family, accept money from friends, and do online recharge
            and bill payment with MobileMoney Wallet.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
        <div class="icon-box">
          <div class="icon"><i class="icofont-credit-card"></i></div>
          <h4 class="title"><a href="">Credit Cards</a></h4>
          <p class="description">Enhance your business with secured online credit card payment processing options
            including Visa, Credit card, Debit card, Mastercard and more...</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
        <div class="icon-box">
          <div class="icon"><i class="icofont-live-support"></i></div>
          <h4 class="title"><a href="">Real Support For 24/7</a></h4>
          <p class="description">provides 24/7 customer support with enhanced assistance and security to the customers
            that enables to solve the issues ontime.</p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End Services Section -->



<!-- ======= Values Section ======= -->
<!-- <section id="values" class="values">
      <div class="container">
        <div class="section-title" data-aos="fade-up">
            <h2>Our Values</h2>
            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
          </div>
        <div class="row">
          <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
            <div class="card" style="background-image: url(assets/img/values-1.jpg);">
              <div class="card-body">
                <h5 class="card-title"><a href="">Our Mission</a></h5>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                <div class="read-more"><a href="#"><i class="icofont-arrow-right"></i> Read More</a></div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="100">
            <div class="card" style="background-image: url(assets/img/values-2.jpg);">
              <div class="card-body">
                <h5 class="card-title"><a href="">Our Plan</a></h5>
                <p class="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem.</p>
                <div class="read-more"><a href="#"><i class="icofont-arrow-right"></i> Read More</a></div>
              </div>
            </div>

          </div>
          <div class="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="200">
            <div class="card" style="background-image: url(assets/img/values-3.jpg);">
              <div class="card-body">
                <h5 class="card-title"><a href="">Our Vision</a></h5>
                <p class="card-text">Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores.</p>
                <div class="read-more"><a href="#"><i class="icofont-arrow-right"></i> Read More</a></div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="300">
            <div class="card" style="background-image: url(assets/img/values-4.jpg);">
              <div class="card-body">
                <h5 class="card-title"><a href="">Our Care</a></h5>
                <p class="card-text">Nostrum eum sed et autem dolorum perspiciatis. Magni porro quisquam laudantium voluptatem.</p>
                <div class="read-more"><a href="#"><i class="icofont-arrow-right"></i> Read More</a></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section> -->
<!-- End Values Section -->



<!-- ======= Testimonials Section ======= -->
<!-- <section id="clients" class="clients">
        <div class="container" data-aos="fade-up">
          <div class="section-title" data-aos="fade-up">
            <h2>Testimonial</h2>
            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
          </div>
          <owl-carousel-o [options]="{items: 1, dots: false, navigation: false}">
              <ng-template carouselSlide>
                  <img src="assets/img/testimonials/testimonials-1.jpg" alt="">
                  <div class="testimonial-item">
                <h3>Saul Goodman</h3>
                <h4>Ceo &amp; Founder</h4>
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
            </div>
            </ng-template>
            <div class="testimonial-item">
            <ng-template carouselSlide>
                <img src="assets/img/testimonials/testimonials-2.jpg" alt="">
                <h3>Saul Goodman</h3>
                <h4>Ceo &amp; Founder</h4>
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
            </ng-template>
            </div>
            <div class="testimonial-item">
            <ng-template carouselSlide>
                <img src="assets/img/testimonials/testimonials-3.jpg" alt="">
                <h3>Saul Goodman</h3>
                <h4>Ceo &amp; Founder</h4>
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
            </ng-template>
            </div>

          </owl-carousel-o>

        </div>
      </section>
     -->
<!-- End Testimonials Section -->

<!-- ========== Partners -->
<app-clients></app-clients>
<!-- End Partners -->

<!-- ======= Pricing Section ======= -->
<section id="pricing" class="pricing">
  <div class="container">

    <div class="section-title">
      <h2 data-aos="fade-up">Pricing</h2>
      <p data-aos="fade-up">Our payment processing fees are the costs that business owners incur when processing
        payments from customers, Businesses that accept credit cards and online payments are charged a small fee per
        transaction, which is referred to as the payment processing fee.</p>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
        <div class="box">
          <h3>Entry Plan</h3>
          <h4><sup>$</sup>100<span> / month</span></h4>
          <h5><sup>$</sup>0<span> - $5000 Monthly Volume</span></h5>
          <ul>
            <li>1% Of transaction volume</li>
            <li>$0 Per transaction</li>
            <li>$0 Security deposit</li>
            <li>$0 Setup fee</li>
            <li>All features included</li>
          </ul>
          <div class="btn-wrap">
            <a href="#" class="btn-buy">Buy Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="100">
        <div class="box featured">
          <h3>Silver Plan</h3>
          <h4><sup>$</sup>50<span> / month</span></h4>
          <h5><sup>$</sup>5k<span> - $100k Monthly Volume</span></h5>
          <ul>
            <li>1% Of transaction volume</li>
            <li>$0 Per transaction</li>
            <li>$0 Security deposit</li>
            <li>$0 Setup fee</li>
            <li>All features included</li>
            <!-- <li class="na">Massa ultricies mi</li> -->
          </ul>
          <div class="btn-wrap">
            <a href="#" class="btn-buy">Buy Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6" data-aos="fade-up">
        <div class="box">
          <h3>Gold Plan</h3>
          <h4><sup>$</sup>0<span> / month</span></h4>
          <h5><sup>$</sup>100k<span> - Above Monthly Volume</span></h5>
          <ul>
            <li>1% Of transaction volume</li>
            <li>$0 Per transaction</li>
            <li>$0 Security deposit</li>
            <li>$0 Setup fee</li>
            <li>All features included</li>
            <!-- <li class="na">Pharetra massa</li>
            <li class="na">Massa ultricies mi</li> -->
          </ul>
          <div class="btn-wrap">
            <a href="#" class="btn-buy">Buy Now</a>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>
<!-- End Pricing Section -->

<!-- ======= F.A.Q Section ======= -->
<!-- <section id="faq" class="faq section-bg">
      <div class="container">

        <div class="section-title">
          <h2 data-aos="fade-up">F.A.Q</h2>
          <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div class="faq-list">
          <ul>
            <li data-aos="fade-up">
              <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Non consectetur a erat nam at lectus urna duis? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                <p>
                  Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="100">
              <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-2" class="collapsed">Feugiat scelerisque varius morbi enim nunc? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-2" class="collapse" data-parent=".faq-list">
                <p>
                  Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="200">
              <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-3" class="collapsed">Dolor sit amet consectetur adipiscing elit? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-3" class="collapse" data-parent=".faq-list">
                <p>
                  Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-4" class="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-4" class="collapse" data-parent=".faq-list">
                <p>
                  Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-5" class="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-5" class="collapse" data-parent=".faq-list">
                <p>
                  Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque.
                </p>
              </div>
            </li>

          </ul>
        </div>

      </div>
    </section> -->
<!-- End F.A.Q Section -->

<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container">

    <div class="section-title">
      <h2 data-aos="fade-up">Contact Us</h2>
      <p data-aos="fade-up">Are you a merchant looking for accelerating your online business? <br> Do you want to find
        out more about our payment gateway? <br> Contact us now to get in-touch</p>
    </div>

    <div class="row justify-content-center">

      <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
        <div class="info-box">
          <i class="bx bx-map"></i>
          <h3>Our Address</h3>
          <p>Salaam Tower, Djibouti</p>
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
        <div class="info-box">
          <i class="bx bx-envelope"></i>
          <h3>Email Us</h3>
          <p>info@waafipay.net<br>info@waafi.com</p>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
        <div class="info-box">
          <i class="bx bx-phone-call"></i>
          <h3>Call Us</h3>
          <p>+253 77797956<br>+253 77502946</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="300">
      <div class="col-xl-9 col-lg-12 mt-4">
        <form [formGroup]="serviceForm" (ngSubmit)="send()" role="form" class="php-email-form">
          <div class="form-row">
            <div class="col-md-6 form-group">
              <input type="text" name="name" class="form-control" formControlName="name" id="name"
                placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars"
                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.name.errors }" />
              <div *ngIf="submitted && serviceForm.controls.name.errors" class="text-danger">
                <div *ngIf="serviceForm.controls.name.errors.required">Name is required</div>
              </div>
            </div>
            <div class="col-md-6 form-group">
              <input type="email" class="form-control" name="email" formControlName="email" id="email"
                placeholder="Your Email" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.email.errors }" />
              <div *ngIf="submitted && serviceForm.controls.email.errors" class="text-danger">
                <div *ngIf="serviceForm.controls.email.errors.required">Email is
                  required</div>
                <div *ngIf="serviceForm.controls.email.errors.email">It`s Not An Email
                  Plz Check</div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <input type="text" class="form-control" name="subject" formControlName="subject" id="subject"
              placeholder="Subject" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.subject.errors }" />
            <div *ngIf="submitted && serviceForm.controls.subject.errors" class="text-danger">
              <div *ngIf="serviceForm.controls.subject.errors.required">Subject is required</div>
            </div>
          </div>
          <div class="form-group">
            <textarea class="form-control" name="message" formControlName="message" rows="5" placeholder="Message"
              [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.message.errors }"></textarea>
            <div *ngIf="submitted && serviceForm.controls.message.errors" class="text-danger">
              <div *ngIf="serviceForm.controls.message.errors.required">Message is required</div>
            </div>
          </div>
          <!-- <div class="mb-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>
          </div> -->
          <div class="text-center"><button class="btn" type="submit">Send Message</button></div>
        </form>
      </div>

    </div>

  </div>
</section>
<!-- End Contact Section -->