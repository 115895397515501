<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex flex-column justify-content-center align-items-center"
  style="margin-bottom: 0px !important;">
  <div class="container" data-aos="fade-in">
    <div class="row">
      <div class="col-6">
        <h1 style="margin-top: 150px;">About WaafiPay</h1>
        <p>WaafiPay is the leading provider of payment services powering ecommerce in Middle East & Africa. Our
          customers include global leaders in new media, travel, payments and ecommerce.</p>
        <p>WaafiPay enables global and cross-border businesses to accept over 200 payment methods across 60 countries
          through a single integration.</p>
        <!-- <div class="d-flex align-items-center">
            <i class="bx bx-down-arrow-alt get-started-icon"></i>
            <a (click)="onClick()" style="cursor: pointer;" class="btn-get-started scrollto">Learn offers Our Merchant</a>
          </div> -->
      </div>
      <div class="col-6">
        <img src="../assets/img/about-us/about-us-2.png" style="margin-left: 35px;" width="110%" height="100%" alt=""
          srcset="">
      </div>
    </div>

  </div>
</section>
<!-- ======= Compliance Section ======= -->
<section id="Compliance" class="Compliance section-bg">
  <div class="container">
    <div class="row">
      <div class="col-7  align-items-stretch justify-content-center">
        <h1 data-aos="fade-up">Compliance.</h1>
        <p>Waafipay LTD is authorised and regulated The Financial Conduct Authority (FCA) takes responsibility for
          regulating all financial services industries in the Middle East & Africa.</p>

      </div>
    </div>

  </div>
</section>
<!-- ======= offers Section ======= -->
<section id="offers" class="offers section-bg">
  <div class="container">
    <div class="row">
      <div class="col-7  align-items-stretch justify-content-center">
        <h3 data-aos="fade-up">This is how we integrate payments.</h3>


        <div class="icon-box" data-aos="fade-up">
          <div class="icon"><i class="icofont-verification-check"></i></div>
          <h4 class="title"><a href="">Merchant & Cashier Providers - <span style="font-weight: 100;">Merchant Account
                Providers give businesses the ability to accept debit and credit cards in payment for goods and services
                and The cashier is a sort of laying or buffering between the merchant and the payment processor</span>
            </a></h4>

        </div>

        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i class="icofont-verification-check"></i></div>
          <h4 class="title"><a href="">Purchasing Mobile wallet & Credit Cars Integration - <span
                style="font-weight: 100;">Mobile payment generally refer to payment services operated under financial
                regulation and performed from or via a mobile device.</span></a></h4>

        </div>

        <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
          <div class="icon"><i class="icofont-verification-check"></i></div>
          <h4 class="title"><a href="">Tap & Pay TapSii NFC Integration - <span style="font-weight: 100;">Tap and pay is
                a form of payment that allows a customer to tap a card or device against your card reader to pay instead
                of inserting a card. When customers want to pay they simply wave or tap their card over the contactless
                terminal</span></a></h4>
        </div>
      </div>
      <div class="col-5  justify-content-center align-items-stretch" data-aos="fade-right">
        <img src="../assets/img/about-us/about-us-1.png" width="100%" height="100%" alt="" srcset="">
      </div>
    </div>

  </div>
</section>
<!-- End offers Section -->

<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container">
    <div class="row">
      <div class="col-lg-6" data-aos="fade-right">
        <div class="">
          <h2 style="margin-bottom: 25px;"><span style="color: #009e44;">About</span> Us</h2>
          <p>If you are interested in receiving more information about Waafipay payment solutions, please send us an
            email at <span style="color: #009e44;">info@waafi.com</span> </p>
          <div style="margin-top: 20px;">
            <ul style="list-style: none;padding: unset">
              <li style="margin-top: 5px; font-size: 20px;">
                <i class="flag-icon flag-icon-dj"> </i> <a style="font-size: 16px;"><span style="font-weight: bold;">
                    Head Office: </span><span>Salaam Tower,
                    Mohamed Kamil Route, Cite Saline Ouest, Djibouti</span></a>
              </li>
              <!-- <li style="margin-top: 5px; font-size: 20px;">
                <i class="flag-icon flag-icon-gb"> </i> <a style="font-size: 16px;"><span style="font-weight: bold;">
                    Regional Office: </span><span>London, UK</span></a>
              </li>
              <li style="margin-top: 5px; font-size: 20px;">
                <i class="flag-icon flag-icon-ca"> </i><a style="font-size: 16px;"><span style="font-weight: bold;">
                    Regional Office: </span><span>Canada, Toronto, Ontario M9W 5Z5</span></a>
              </li>
              <li style="margin-top: 5px; font-size: 20px;">
                <i class="flag-icon flag-icon-nl"> </i><a style="font-size: 16px;"><span style="font-weight: bold;">
                    Regional Office: </span><span>Netherlands, Amsterdam</span></a>
              </li>
              <li style="margin-top: 5px; font-size: 20px;">
                <i class="flag-icon flag-icon-se"> </i><a style="font-size: 16px;"><span style="font-weight: bold;">
                    Regional Office: </span><span>Sweden, Stockholm</span></a>
              </li>
              <li style="margin-top: 5px; font-size: 20px;">
                <i class="flag-icon flag-icon-pk"> </i><a style="font-size: 16px;"><span style="font-weight: bold;">
                    Regional Office: </span><span>Pakistan, Islamabad</span></a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.444144444992!2d43.140874950470966!3d11.591650646745503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x162301195c21def3%3A0x347cf2fa0e913859!2sSalaam%20Center%2C%20Salaam%20Tower!5e0!3m2!1sen!2str!4v1673874879020!5m2!1sen!2str"
          width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
        <!-- <iframe style="border:0; width: 100%; height: 100%;" src="https://goo.gl/maps/s8RauHCX64u4X7Zj9" frameborder="0" allowfullscreen></iframe> -->
      </div>
    </div>

  </div>
</section><!-- End Contact Section -->