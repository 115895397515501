
<!-- ======= Cars Section ======= -->
<section id="hero" class="d-flex flex-column justify-content-center align-items-center" style="margin-bottom: 0px !important;">
    <div class="container" data-aos="fade-in">
      <div class="row">
        <div class="col-6">
          <h1>Wages Protection payment platform.</h1>
          <p>Our Credit card Supplier payment remains a challenge for businesses, with slow internal processes and insufficient automation. </p>
          <div class="d-flex align-items-center">
            <i class="bx bx-down-arrow-alt get-started-icon"></i>
            <a (click)="onClick()" style="cursor: pointer;" class="btn-get-started scrollto">Learn Our Wages Protection</a>
          </div>
        </div>
        <div class="col-6">
          <img src="../assets/img/pay-salary/pay-salary-4.png" style="margin-left: 35px;" width="110%" height="100%" alt="" srcset="">
        </div>
      </div>
     
    </div>
  </section>
   <!-- ======= wages Section ======= -->
   <section id="wages" class="wages section-bg">
    <div class="container">
      <div class="row">
        <div class="col-5  justify-content-center align-items-stretch" data-aos="fade-right">
            <img src="../assets/img/pay-salary/pay-salary-3.png" width="100%" height="100%" alt="" srcset="">
        </div>

        <div class="col-7  align-items-stretch justify-content-center">
          <h3 data-aos="fade-up">Wages Protection payment Solutions</h3>
    

          <div class="icon-box" data-aos="fade-up">
            <div class="icon"><i class="icofont-company"></i></div>
            <h4 class="title"><a href="">Companies  - <span style="font-weight: 100;">Wages and salaries are typically paid directly to an employee in the form of cash or in a cash equivalent, such as by wallet or by direct deposit into the employee's bank account or an account directed by the employee</span> </a></h4>
            
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-institution"></i></div>
            <h4 class="title"><a href="">Institutions  - <span style="font-weight: 100;">Institutions Wages and salaries are paid directly to an employee in his bank account, such as by wallet or by direct deposit into the employee's bank account or an account directed by the employee</span></a></h4>
    
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-unity-hand"></i></div>
            <h4 class="title"><a href="">Non-Governmental Organisation - <span style="font-weight: 100;">NGO's salaries are paid directly to thier an employee thier Bank account, such as by wallet or by direct deposit into the employee's bank account or an account directed by the employee</span></a></h4>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!-- End wages Section -->
