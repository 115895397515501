import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-woo-commerce',
  templateUrl: './woo-commerce.component.html',
  styleUrls: ['./woo-commerce.component.css']
})
export class WooCommerceComponent implements OnInit {

  constructor(
    private pageTitle: PageTitleService
  ) { 
    pageTitle.setTitle('WooCommerce v1.0x')
  }

  ngOnInit(): void {
  }

}
