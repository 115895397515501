  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients">
    <div class="container" data-aos="fade-up">
      <div class="section-title" data-aos="fade-up">
        <h2>Our Partners</h2>
        <p>Our payment gateway partner benefits. First of all, you get access to our partner administration, which gives you a good overview of your customers. The partner administration also allow fast creation of payment gateway agreement for your customers, where Reepay will assist you and your customers.</p>
      </div>
      <div>
        <owl-carousel-o [options]="{items: 4, dots: false, navigation: false}">
          <ng-template carouselSlide>
            <img src="assets/img/partners/hormuud-logo.png" alt="">
          </ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/salaam-bank-logo.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/Telesom-logo.png" alt=""></ng-template>  
          <!-- <ng-template carouselSlide><img src="assets/img/partners/salam-african-logo.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/golis-logo.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/ebirr-logo.png" alt=""></ng-template>   -->
          <!-- <ng-template carouselSlide><img src="assets/img/partners/client-6.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/client-7.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/client-8.png" alt=""></ng-template>   -->
        </owl-carousel-o>

      </div>
      <div style="margin-top: 50px;">
        <owl-carousel-o  [options]="{items: 4, dots: false, navigation: false}">
          <!-- <ng-template carouselSlide>
            <img src="assets/img/partners/hormuud-logo.png" alt="">
          </ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/salaam-bank-logo.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/Telesom-logo.png" alt=""></ng-template>   -->
          <ng-template carouselSlide><img src="assets/img/partners/salam-african-logo.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/golis-logo.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/ebirr-logo.png" alt=""></ng-template>  
          <!-- <ng-template carouselSlide><img src="assets/img/partners/client-6.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/client-7.png" alt=""></ng-template>  
          <ng-template carouselSlide><img src="assets/img/partners/client-8.png" alt=""></ng-template>   -->
        </owl-carousel-o>

      </div>

    </div>
  </section><!-- End Clients Section -->