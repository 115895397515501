
<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex flex-column justify-content-center align-items-center" style="margin-bottom: 0px !important;">
    <div class="container" data-aos="fade-in">
      <div class="row">
        <div class="col-6">
          <h1>The easiest way to pay a pill in our payment platform.</h1>
          <p>Our solution is conveniently integrated into the world’s leading e-commerce platforms as well as available directly to our customers who prefer to use our state of the art payment APIs.</p>
          <div class="d-flex align-items-center">
            <i class="bx bx-down-arrow-alt get-started-icon"></i>
            <a (click)="onClick()" style="cursor: pointer;" class="btn-get-started scrollto">Learn Our Tap & Pay TapSii</a>
          </div>
        </div>
        <div class="col-6">
          <img src="../assets/img/tapsii/mobile-pay-5.png" style="margin-left: 35px;" width="110%" height="100%" alt="" srcset="">
        </div>
      </div>
     
    </div>
  </section>
   <!-- ======= offers Section ======= -->
   <section id="offers" class="offers section-bg">
    <div class="container">
      <div class="row">
        <div class="col-5  justify-content-center align-items-stretch" data-aos="fade-right">
            <img src="../assets/img/tapsii/mobile-pay-7.png" width="100%" height="100%" alt="" srcset="">
        </div>

        <div class="col-7  align-items-stretch justify-content-center">
          <h3 data-aos="fade-up">WaaafiPay contains multiple payment Solutions</h3>
    

          <div class="icon-box" data-aos="fade-up">
            <div class="icon"><i class="icofont-verification-check"></i></div>
            <h4 class="title"><a href="">Tap & Pay NFC Device Integration - <span style="font-weight: 100;">While mobile payments may be the easiest way to understand NFC, the technology can be applied to more than tap-and-go purchases.</span> </a></h4>
            
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-verification-check"></i></div>
            <h4 class="title"><a href="">POS Device Integration - <span style="font-weight: 100;">POS systems allow retailers, restaurants and other similar organizations effectively manage operating tasks and in-store sales and purchases from scanning product data and printing receipts to swiping a credit/debit card and entering credentials on a pinpad. </span></a></h4>
    
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-verification-check"></i></div>
            <h4 class="title"><a href="">Web Interface Integration - <span style="font-weight: 100;">In a user interface integration, merchant and cashier web interfaces are integrated so that a user can carry out an operation that involves two different web interfaces – without having to take into account that merchanta or cashiers is actually running one web interface</span></a></h4>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!-- End offers Section -->
   <!-- ======= nfc Section ======= -->
   <section id="nfc" class="nfc section-bg" style="background-color: #fff !important;">
    <div class="container">
      <div class="row">
        <div class="col-7  align-items-stretch justify-content-center">
          <h3 data-aos="fade-up">You can pay all pills through our Service</h3>
    

          <div class="icon-box" data-aos="fade-up">
            <div class="icon"><i class="icofont-opencart"></i></div>
            <h4 class="title"><a href="">Pay Shoping Merchant Pills - <span style="font-weight: 100;">Pay Mobile wallet or a mobile tablet POS solution allows users to process payments from anywhere. With a mobile payment app, setup on your tablet is easy!</span> </a></h4>
            
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-travelling"></i></div>
            <h4 class="title"><a href="">Pay Travel Booking Pills - <span style="font-weight: 100;">When making your flight booking, you may wish to pay using more than one form of payment through POS or Mobile wallet. </span></a></h4>
    
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-ui-home"></i></div>
            <h4 class="title"><a href="">Pay Rent Pills - <span style="font-weight: 100;">Pay your rental pills easily, you may wish to pay using more than one form of payment through POS or Mobile wallet.</span></a></h4>
          </div>
          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-lamp-light"></i></div>
            <h4 class="title"><a href="">Pay Electricity Pills - <span style="font-weight: 100;">Pay your Electricity pills easily, you may wish to pay using more than one form of payment through POS or Mobile wallet.</span></a></h4>
          </div>
          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-water-drop"></i></div>
            <h4 class="title"><a href="">Pay Water Pills - <span style="font-weight: 100;">Pay your Water pills easily, you may wish to pay using more than one form of payment through POS or Mobile wallet.</span></a></h4>
          </div>
        </div>

        <div class="col-5  justify-content-center align-items-stretch" data-aos="fade-right">
            <img src="../assets/img/tapsii/mobile-pay-12.png" width="100%" height="80%" alt="" srcset="">
        </div>
      </div>

    </div>
  </section>
  <!-- End nfc Section -->
